function windowWidth() {
    return $(window).width();
}

function changeBackground(result) {
    if (windowWidth() >= 992) {
        var n;
        if (result < 0.2) {
            n = 1;
        } else if (result >= 0.2 && result < 1) {
            n = 2;
        } else if (result >= 1 && result < 2) {
            n = 3;
        } else if (result >= 2 && result < 5) {
            n = 4;
        } else if (result >= 5) {
            n = 5;
        }
        $('.breathalyser__test-result').css({
            backgroundImage: 'url("img/breathalyser-form/bg-' + n + '.jpg")'
        });
    }

}

function removeBackground() {
    if (windowWidth() < 992) {
        $('.breathalyser__test-result').removeAttr('style');
    }
}

function animate(el, t, width) {
    var translation = 50;
    if (t) {
        if (width < 768) {
            translation = 50;
        } else {
            translation = 70;
        }
        $('html, body').animate({
            scrollTop: $(el).offset().top - translation
        }, 1000, 'easeInOutExpo', function () {
            $('#update-value, #facebook').addClass('active');
        });
    } else {
        $('html, body').animate({
            scrollTop: $(el).offset().top - 70
        }, 1000, 'easeInOutExpo').clearQueue();
    }
}

function removePopupMsg(el) {
    $(el).parents('.form-group').find('.incorrect-value').remove();
}

function validation(obj) {
    var validationStatus;
    var regExp = /^0[0-9]{1,}/g;
    $('#breathalyser-form input, #breathalyser-form select').each(function () {
        var fieldValue = $(this).val();
        var fieldID = $(this).attr('id');
        var patternValue = regExp.test(fieldValue);

        if (fieldID === 'weight' || fieldID === 'height' || fieldID === 'age') {
            if (fieldValue === '' || patternValue === true) {
                removePopupMsg($(this));
                $(this).parent().before('<span class="incorrect-value">Niepoprawna wartość</span>');
                validationStatus = 'error';
            } else if (parseInt(fieldValue) <= 0) {
                removePopupMsg($(this));
                $(this).parent().before('<span class="incorrect-value">Wartość musi być większa od 0</span>');
                validationStatus = 'error';
            } else {
                removePopupMsg($(this));
            }
        } else {
            if (fieldValue === '' || patternValue === true || fieldValue < 0) {
                removePopupMsg($(this));
                $(this).parent().before('<span class="incorrect-value">Niepoprawna wartość</span>');
                validationStatus = 'error';
            } else {
                removePopupMsg($(this));
            }
        }
    });

    if (validationStatus === 'error') {
        initDataLayer(obj);
        animate('.incorrect-value');
        return false;
    } else {
        animate('.breathalyser__test-result', true, windowWidth());
        checkResult();
        initDataLayer(obj);
    }
}

function changeValue(el, name) {
    var value = parseInt(el.parent().find('input').val());
    var min = parseInt(el.parent().find('input').attr('min'));
    var max = parseInt(el.parent().find('input').attr('max'));
    if (name === 'up' && value < max) {
        value++;
    } else if (name === 'down' && value > min) {
        value--;
    }
    el.parent().find('input').val(value);
}

function checkResult() {
    // Objetosc wypitego piwa [ml]
    var beerVol = parseInt($('#beer').val()) * 500;

    // Objetosc wypitego wina [ml]
    var wineVol = parseInt($('#wine').val()) * 150;

    // Objetosc wypitej wodki [ml]
    var vodkaVol = parseInt($('#vodka').val()) * 50;

    var sexValue = $('#sex').val();
    var weightValue = parseInt($('#weight').val());
    var heightValue = parseInt($('#height').val());
    var ageValue = parseInt($('#age').val());

    var timeValue = parseInt($('#time').val());

    // Wspolczynnik eliminiacji alkoholu z organizmu
    var elimination = {
        ratio1: 0.1, // Osoby pijace rzadko
        ratio2: 0.15, // Osoby pijace przecietnie
        ratio3: 0.2 // Osoby pijace czesto
    };

    // Suma ilosci, wypitego czystego alkoholu [g]
    function sumAlk(b, w, v) {
        var result;
        result = ((b * 0.05) + (w * 0.12) + (v * 0.4)) * 0.79;
        return result;
    }
    var sumalk = sumAlk(beerVol, wineVol, vodkaVol);
    // console.log('Ilosc czystego alkoholu: ' + sumalk);

    // Ilosc plynow ustrojowych w organizmie
    function bodyFluids(s, w, h, a) {
        var result;
        if (s === 'male') {
            result = 2.447 - (0.09156 * a) + (0.1074 * h) + (0.3362 * w);
        } else if (s === 'female') {
            result = -2.097 + (0.1069 * h) + (0.2466 * w);
        }
        return result;
    }
    var bodyfluids = bodyFluids(sexValue, weightValue, heightValue, ageValue);
    // console.log('Ilosc plynow ustrojowych: ' + bodyfluids);

    // Szybkosc eliminacji alkoholu z organizmu w czasie [h]
    function eliminationAlcohol(t) {
        var result;
        result = t * elimination.ratio2;
        return result;
    }
    var eliminationalcohol = eliminationAlcohol(timeValue);
    // console.log('Ile alkoholu mniej po czasie(' + timeValue + 'h): ' + eliminationalcohol);

    // Wyswietlenie wyniku
    function permilRes(s, b, e) {
        var result;
        result = (s / b) * 0.8 - e;

        changeBackground(result.toFixed(2));

        if (result < 0 || result === 0) {
            result = '0.00';
        } else if (result > 5) {
            result = '>5.00';
        } else {
            result = result.toFixed(2);
        }
        return result;
    }
    var permilres = permilRes(sumalk, bodyfluids, eliminationalcohol);
    //console.log('Promile: ' + permilres);
    $('#alcohol-content').text(permilres + ' \u2030');
    $('#update-value').text(permilres);

    // Pozostały czas do wytrzezwienia
    function remainingTime() {
        var time;
        if (parseFloat(permilres) < 5) {
            time = '~ ' + Math.ceil(parseFloat(permilres) / parseFloat(elimination.ratio2)) + ' godzin';
        } else {
            time = 'Dawka śmiertelna';
        }
        return time;
    }
    var remainingtime = remainingTime();
    // console.log('Pozostaly czas: ' + remainingtime);
    $('#sobriety').text(remainingtime);
}

// Analityka Google
function initDataLayer(obj) {

    // Waga
    var weightRange = [{
        min: 0,
        max: 60
    }, {
        min: 61,
        max: 80
    }, {
        min: 81,
        max: 100
    }, {
        min: 101,
        max: '\u221E'
    }];
    var weight = obj[4].value;
    for (var a = 0; a < weightRange.length; a++) {
        if (weight >= weightRange[weightRange.length - 1].min) {
            weight = weightRange[weightRange.length - 1].min + '-' + weightRange[weightRange.length - 1].max;
        } else if (weight >= weightRange[a].min && weight <= weightRange[a].max) {
            weight = weightRange[a].min + '-' + weightRange[a].max;
        }
    }

    // Wzrost
    var heightRange = [{
        min: 0,
        max: 150
    }, {
        min: 151,
        max: 180
    }, {
        min: 181,
        max: 190
    }, {
        min: 191,
        max: 200
    }, {
        min: 201,
        max: '\u221E'
    }];
    var height = obj[5].value;
    for (var b = 0; b < heightRange.length; b++) {
        if (height >= heightRange[heightRange.length - 1].min) {
            height = heightRange[heightRange.length - 1].min + '-' + heightRange[heightRange.length - 1].max;
        } else if (height >= heightRange[b].min && height <= heightRange[b].max) {
            height = heightRange[b].min + '-' + heightRange[b].max;
        }
    }

    // Wiek
    var ageRange = [{
        min: 0,
        max: 18
    }, {
        min: 19,
        max: 24
    }, {
        min: 25,
        max: 34
    }, {
        min: 35,
        max: 44
    }, {
        min: 45,
        max: 54
    }, {
        min: 55,
        max: '\u221E'
    }];
    var age = obj[6].value;
    for (var c = 0; c < ageRange.length; c++) {
        if (age >= ageRange[ageRange.length - 1].min) {
            age = ageRange[ageRange.length - 1].min + '-' + ageRange[ageRange.length - 1].max;
        } else if (age >= ageRange[c].min && age <= ageRange[c].max) {
            age = ageRange[c].min + '-' + ageRange[c].max;
        }
    }

    // Plec
    var sex;
    if (obj[7].value === 'male') {
        sex = 'Mężczyzna';
    } else if (obj[7].value === 'female') {
        sex = 'Kobieta';
    }

    // Wynik pomiaru
    var resultRange = [{
        min: 0.00,
        max: 0.20
    }, {
        min: 0.21,
        max: 0.50
    }, {
        min: 0.51,
        max: 0.99
    }, {
        min: 1.00,
        max: 1.99
    }, {
        min: 2.00,
        max: 3.99
    }, {
        min: 4.00,
        max: '\u221E'
    }];
    var result = $('#update-value').text();
    for (var i = 0; i < resultRange.length; i++) {
        if (result >= resultRange[resultRange.length - 1].min) {
            result = (resultRange[resultRange.length - 1].min).toFixed(2) + '-' + resultRange[resultRange.length - 1].max;
        } else if (result >= resultRange[i].min && result <= resultRange[i].max) {
            result = (resultRange[i].min).toFixed(2) + '-' + (resultRange[i].max).toFixed(2);
        }
    }

    // Przekazanie literału obiektowego do tablicy (dataLayer)
    dataLayer.push({
        'Waga': weight, // Waga
        'Wzrost': height, // Wzrost
        'Wiek': age, // Wiek
        'Płeć': sex, // Plec
        'Wynik': result, // Wynik pomiaru
        'event': 'WynikBadania'
    });
}

$(document).ready(function () {
    $('#breathalyser-form').on('submit', function (event) {
        event.preventDefault();
        var obj = event.target;
        validation(obj);
    });

    $('#breathalyser-form input').on('focus', function (event) {
        var el = '#' + event.target.name;
        removePopupMsg(el);
        $('#update-value, #facebook').removeClass('active');
    });

    $('#breathalyser-form input, #sex').on('change', function (event) {
        var el = '#' + event.target.name;
        removePopupMsg(el);
        $('#update-value, #facebook').removeClass('active');
    });

    $('[type="reset"]').on('click', function () {
        $('.incorrect-value').remove();
        $('#alcohol-content, #sobriety').text('--');
        $('#update-value').text('0.00');
        animate('#test');
        $('#update-value, #facebook').removeClass('active');
        changeBackground(0);
    });

    $('#breathalyser-form .controls > span').on('click', function (event) {
        var className = event.target.className;
        var str = className.substring(className.lastIndexOf('-') + 1, className.length);
        removePopupMsg($(this));
        changeValue($(this), str);
    });

    $(window).on('resize', function () {
        removeBackground();
    });
});