(function ($) {
    $(document).ready(function () {
        // Konfiguracja
        config = {
            cname: 'COOKIEINFO',
            cvalue: 'disabled',
            exdays: 30
        };

        // Sprawdzanie cookie
        function checkCookie() {
            var getCookies = document.cookie;
            var pattern = new RegExp(/COOKIEINFO=disabled/g);
            var status = pattern.test(getCookies);
            if (status === false) {
                $('#cookie-info').show();
            }
        }

        // Dodawanie cookie
        function setCookie(cname, cvalue, exdays) {
            $('#cookie-info').hide();
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires;
        }

        checkCookie();

        $('.close-cookie-info').click(function () {
            setCookie(config.cname, config.cvalue, config.exdays);
        });
    });
}(jQuery));