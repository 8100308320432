/**
 * Slider
 * Dependents: bootstrap, jQuery
 */

(function ($, w) {
    var cachedWindowWidth = $(w).width(),
        slider = {

            sliderItems: function () {
                return $('#slider .slider__items');
            },

            sliderItem: function () {
                return $('#slider .slider__item');
            },

            interval: null,

            config: {
                easing: 'swing', // easeInSine, easeOutSine, easeInOutSine, easeInQuad, easeOutQuad, easeInOutQuad, easeInCubic, easeOutCubic, easeInOutCubic, easeInQuart, easeOutQuart, easeInOutQuart, easeInQuint, easeOutQuint, easeInOutQuint, easeInExpo, easeOutExpo, easeInOutExpo, easeInCirc, easeOutCirc, easeInOutCirc, easeInBack, easeOutBack, easeInOutBack, easeInElastic, easeOutElastic, easeInOutElastic, easeInBounce, easeOutBounce, easeInOutBounce
                enable: true, // boolean (true / false)
                enableInterval: true, // boolean (true / false)
                intervalDuration: 5000, // Interval duration (ms)
                //numberOfSlidesToMove: 1, // The number of slides to move on transition.
                mode: [{
                    minWidth: 0, // Pixel width
                    maxWidth: 768, // Pixel width
                    name: 'grid' // slider, grid
                }, {
                    minWidth: 768, // Pixel width
                    maxWidth: 992, // Pixel width
                    name: 'slider' // slider, grid
                }, {
                    minWidth: 992, // Pixel width
                    maxWidth: 1200, // Pixel width
                    name: 'slider' // slider, grid
                }, {
                    minWidth: 1200, // Pixel width
                    maxWidth: 5120, // Pixel width
                    name: 'slider' // slider, grid
                }],
                random: true, // Random slides, boolean (true / false)
                speed: 500, // Slide transition duration (ms)
                //touchEnabled: false, // If true, slider will allow touch swipe transitions, boolean (true / false)
            },

            elWidth: function (el) {
                return el.outerWidth();
            },

            elLength: function (el) {
                return el.length;
            },

            checkMode: function () {
                var windowWidth = this.elWidth($(w));

                for (var i = 0; i < this.config.mode.length; i++) {
                    if (windowWidth >= this.config.mode[i].minWidth && windowWidth < this.config.mode[i].maxWidth) {
                        return this.config.mode[i].name;
                    }
                }
            },

            init: function () {
                var mode = this.checkMode();

                if (mode === 'slider') {

                    this.resetInlineStyle();

                    if (this.config.random === true) {
                        this.randomSlides();
                    }

                    this.active();

                    if ('ontouchstart' in document.documentElement === true) {
                        if (this.config.touchEnabled === true) {
                            // Touch swipe transitions
                            this.swipe(mode);
                        }
                    } else {
                        if (this.config.enableInterval === true) {
                            this.stopAutoMove();
                            this.autoMove(this.config.intervalDuration);
                            this.hover(mode);
                        }
                    }

                } else if (mode === 'grid') {
                    this.resetInlineStyle();
                    this.stopAutoMove();
                    this.hover();
                }
            },

            resetInlineStyle: function () {
                var sliderItem = this.sliderItem();

                $('#slider').removeClass('active');
                sliderItem.removeAttr('style').parent().removeAttr('style');
            },

            active: function () {
                var sliderItem = this.sliderItem();
                var sliderItemLength = this.elLength(sliderItem);

                $('#slider').addClass('active');
                sliderItem.css({
                    'width': this.elWidth(sliderItem)
                }).parent().css({
                    'width': (this.elWidth(sliderItem) * sliderItemLength)
                });
            },

            move: function (btn) {
                var sliderItem = this.sliderItem();

                function cloneElement(position) {
                    var $element = $('.slider__items').find('.slider__item:' + position);
                    if (position === 'first') {
                        $('.slider__items').append($element.clone());
                    } else if (position === 'last') {
                        $('.slider__items').prepend($element.clone());
                    }
                    $element.remove();
                }

                if (btn === 'left') {
                    $('.slider__items').each(function () {
                        $(this).animate({
                            'left': '-=' + slider.elWidth(sliderItem)
                        }, 0, function () {
                            cloneElement('last');
                            $(this).animate({
                                'left': '+=' + slider.elWidth(sliderItem)
                            }, slider.config.speed, slider.config.easing, function () {
                                $(this).clearQueue();
                            });
                        });
                    });
                } else if (btn === 'right') {
                    $('.slider__items').each(function () {
                        $(this).animate({
                            'left': '-=' + slider.elWidth(sliderItem)
                        }, slider.config.speed, slider.config.easing, function () {
                            $(this).clearQueue();
                            cloneElement('first');
                            $(this).css('left', 0);
                        });
                    });
                }
            },

            autoMove: function (duration) {
                this.interval = setInterval(function () {
                    slider.move('right');
                }, duration);
            },

            stopAutoMove: function () {
                clearInterval(this.interval);
            },

            hover: function (mode) {
                $('#slider').off('mouseenter mouseleave');
                if (mode === 'slider') {
                    $('#slider').hover(function () {
                        slider.stopAutoMove();
                    }, function () {
                        slider.autoMove(slider.config.intervalDuration);
                    });
                }
            },

            randomSlides: function () {
                var sliderItems = this.sliderItems();
                var sliderItem = this.sliderItem();
                var sliderItemArray = [];
                var sliderItemIndexArray = [];

                sliderItem.each(function (index) {
                    sliderItemArray.push($(this));
                    sliderItemIndexArray.push(index);
                    $(this).remove();
                });

                // Sorting an array in random order
                sliderItemIndexArray.sort(function (a, b) {
                    return 0.5 - Math.random();
                });

                for (var i = 0; i < sliderItemIndexArray.length; i++) {
                    sliderItems.append(sliderItemArray[sliderItemIndexArray[i]]);
                }
            },

            swipe: function (mode) {
                $('#slider .slider__inner').off('touchstart');
                if (mode === 'slider') {
                    $('#slider .slider__inner').on('touchstart', function (event) {
                        console.log(event.originalEvent);
                    });
                    $('#slider .slider__inner').on('touchmove', function (event) {
                        console.log(event.originalEvent);
                    });
                    $('#slider .slider__inner').on('touchend', function (event) {
                        console.log(event.originalEvent);
                    });
                }
            }
        }

    if (slider.config.enable === true) {
        $(document).ready(function () {
            slider.init();
        });

        $(w).on('resize', function () {
            var newWidth = $(this).width();
            if (newWidth !== cachedWindowWidth) {
                slider.init();
                cachedWindowWidth = newWidth;
            }
        });

        $('.slider__control').on('click', function () {
            slider.move($(this).attr('data-slider-btn'));
        });
    }
}(jQuery, window));