/*!
 * Start Bootstrap - Agency Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function () {
    $('a.page-scroll').bind('click', function (event) {
        event.preventDefault();
        var $anchor = $(this);
        var pos = 0;
        if ($(window).width() < 768) {
            pos = 50;
        } else {
            pos = 70;
        }
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - pos
        }, 1000, 'easeInOutExpo');
    });
});

// Highlight the top nav as scrolling occurs
$('body').scrollspy({
    target: '.navbar-fixed-top',
    offset: 110
});

// Closes the Responsive Menu on Menu Item Click
$('.navbar-collapse ul li a').click(function () {
    $('.navbar-toggle:visible').click();
});


$('#myModal').on('shown.bs.modal', function () {
    $('#myInput').focus();
});

$(document).on("pagecreate", function () {
    $(".photopopup").on({
        popupbeforeposition: function () {
            var maxHeight = $(window).height() - 60 + "px";
            $(".photopopup img").css("max-height", maxHeight);
        }
    });
});

// Find all YouTube videos
var $allVideos = $("iframe[src^='//www.youtube.com']"),
    // The element that is fluid width
    $fluidEl = $("body");
// Figure out and save aspect ratio for each video
$allVideos.each(function () {
    $(this).data('aspectRatio', this.height / this.width)
        // and remove the hard coded width/height
        .removeAttr('height').removeAttr('width');
});
// When the window is resized
$(window).resize(function () {
    var newWidth = $fluidEl.width();
    // Resize all videos according to their own aspect ratio
    $allVideos.each(function () {
        var $el = $(this);
        $el.width(newWidth).height(newWidth * $el.data('aspectRatio'));
    });
    // Kick off one resize to fix all videos on page load
}).resize();